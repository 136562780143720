import Vuex from 'vuex';
import Vue from 'vue';
import global from './modules/global';
import auth from './modules/auth';
import questions from './modules/questions';
import translations from './modules/translations';
import matches from './modules/matches';
import visitors from './modules/visitors';
import chat from './modules/chat';
import preferences from './modules/preferences';
import meetings from './modules/meetings';
import home from './modules/home';
import membershipProfile from './modules/membershipProfile';
import memberProfileModule from './modules/memberProfile';
import userInterests from './modules/userInterests';
import userGallery from './modules/userGallery';
import errorHandling from './modules/errorHandling';
import payments from './modules/payments';
import myAccount from './modules/myAccount';
import onlineUsers from './modules/onlineUsers';




Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        global,
        auth,
        questions,
        translations,
        matches,
        visitors,
        chat,
        preferences,
        meetings,
        home,
        membershipProfile,
        memberProfileModule,
        userInterests,
        userGallery,
        errorHandling,
        payments,
        myAccount,
        onlineUsers,
    }
});