<template>
  <div
    :class="{superadmin: this.$store.getters.userRole.includes('ROLE_PAGE_TRANSLATOR'), editableContent: this.$store.getters.editable }"
    id="stickySection"
    class=""
  >
    <!-- <p v-langErr>test</p> -->
    <header id="membershipHeader">
      <div id="headerWrapper" class="container-lg">
        <div id="logoWrapper">
          <router-link to="/membership-area/home">
            <img v-if="!$store.getters.isMobile || premium" class="logo logo-desktop" :src="$store.getters.mainLogos.desktop" />
            <img v-else class="logo logo-mobile" :src="$store.getters.mainLogos.mobile" />
          </router-link>
        </div>
        <div id="navigation" class="itemsWrapper" v-if="!premium">
          <router-link
            to="/membership-area/messages"
            class="navItem"
            :class="[
              $route.path.indexOf('/membership-area/messages') != -1 ? 'active' : ''
            ]"
          >
            <div
              class="messages navItemWrapper"
            >
              <div class="infoWrapper navItem-icon">
                <div class="messagesIcon icon"></div>
                <div
                  class="number"
                  v-if="this.$store.getters.chatNewMessages > 0 && this.$store.getters.chatNewMessages < 100"
                >{{ this.$store.getters.chatNewMessages }}</div>
                <div class="number bigNum" v-else-if="this.$store.getters.chatNewMessages >= 100">99+</div>
              </div>
              <p
                class="headerMessages navItem-text"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipHeader.messages =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipHeader.messages
                }}
              </p>
            </div>
          </router-link>
          <router-link
            to="/membership-area/online"
            class="navItem"
            :class="[
              $route.path == '/membership-area/online' ? 'active' : '',
              $route.path == '/membership-area/visitors' ? 'next' : ''
            ]"
          >
            <div
              class="visitors navItemWrapper"
              v-if="showOnlineUserMenu"
            >
              <div class="infoWrapper navItem-icon">
                <div class="onlineIcon icon"></div>
                <div
                  class="number"
                  v-if="onlineUsers.length > 0 && onlineUsers.length < 100"
                >{{ onlineUsers.length }}</div>
              </div>
              <p
                class="headerVisitors navItem-text"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipHeader.onlineUsers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale+'.keys.membershipArea.membershipHeader.onlineUsers')
                }}
              </p>
            </div>
          </router-link>
          <router-link
            to="/membership-area/matches"
            class="navItem"
            :class="[
              $route.path == '/membership-area/matches' ? 'active' : '',
              $route.path.indexOf('/membership-area/messages') != -1 ? 'next' : ''
            ]"
          >
            <div
              class="matches navItemWrapper"
            >
              <div class="infoWrapper navItem-icon">
                <div class="matchesIcon icon"></div>
                <div
                  class="number"
                  v-if="$store.getters.matchesQuantity > 0 && $store.getters.matchesQuantity < 100"
                >{{ $store.getters.matchesQuantity }}</div>
                <div
                  class="number bigNum"
                  v-else-if="$store.getters.matchesQuantity >= 100"
                >99+</div>
              </div>
              <p
                class="headerMatches navItem-text"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipHeader.matches =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipHeader.matches
                }}
              </p>
            </div>
          </router-link>
          <router-link
            to="/membership-area/visitors"
            class="navItem"
            :class="[
              $route.path == '/membership-area/visitors' ? 'active' : '',
              $route.path == '/membership-area/matches' ? 'next' : ''
            ]"
          >
            <div
              class="visitors navItemWrapper"

            >
              <div class="infoWrapper navItem-icon">
                <div class="visitorsIcon icon"></div>
                <div class="number" v-if="visitorsQuantity > 0 && visitorsQuantity < 100">{{ visitorsQuantity }}</div>
                <div class="number bigNum" v-else-if="visitorsQuantity >= 100">99+</div>
              </div>
              <p
                class="headerVisitors navItem-text"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipHeader.visitors =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipHeader.visitors
                }}
              </p>
            </div>
          </router-link>
          <router-link to="/membership-area/meetings"
            class="navItem"
            :class="[
              $route.path == '/membership-area/meetings' ? 'active' : '',
              $route.path == '/membership-area/visitors' ? 'next' : ''
            ]"
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM') && !totalMeetings == 0"
          >
            <div
              class="meetings navItemWrapper"

            >
              <div class="infoWrapper navItem-icon">
                <div class="meetingsIcon icon"></div>
                <div class="number" v-if="totalMeetings > 0 && totalMeetings < 100">{{ totalMeetings }}</div>
                <div class="number bigNum" v-else-if="totalMeetings >= 100">99+</div>
              </div>
              <p
                class="headerHaveYouMet navItem-text"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipHeader.haveYouMet =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipHeader
                .haveYouMet
                }}
              </p>
            </div>
          </router-link>
          <div
            id="memberPhotoWrapper"
            :class="[$route.path == '/membership-area/meetings' ? 'next' : '']"
            @mouseover="hover = true"
            @mouseleave="hover = false"
          >
          <img v-if="profilePic"  class="memberPhoto" :src="profilePic" />
           <!--
            <div
              v-if="profilePic"
              class="memberPhoto"
              v-bind:style="{
                backgroundImage: 'url(' + profilePic + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
              }"
            ></div>
-->
            <div
              v-if="!profilePic && (parseInt(profileGender) == 1)"
              class="memberPhoto"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-man.svg')
              }"
            ></div>
            <div
              v-if="!profilePic && (parseInt(profileGender) == 2)"
              class="memberPhoto"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-woman.svg')
              }"
            ></div>
            <transition :duration="{ enter: 0, leave: 300 }">
            <div class="desktopMenuDropDown" v-if="hover">
              <div
                @click="$router.push('/membership-area/home'), (hover = false)"
                :class="[
                  $route.path == '/membership-area/home' ? 'active' : ''
                ]"
                class="dropDownItem"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipHeader.home =
                    $event.target.innerText
                "
              >
                <div class="homeIcon icon"></div>
                {{ $t($i18n.locale).keys.membershipArea.membershipHeader.home }}
              </div>
              <div
                v-langErr
                @click="$router.push('/membership-area/user-profile/profile'),(hover = false)"
                :class="[
                  $route.path == '/membership-area/user-profile/profile'
                    ? 'active': ''
                ]"
                class="dropDownItem"
              >
                <div
                  class="profileIcon  icon"
                  :contenteditable="editable"
                  @blur="
                    $t(
                      $i18n.locale
                    ).keys.membershipArea.membershipHeader.profile =
                      $event.target.innerText
                  "
                ></div>
                {{ $t($i18n.locale).keys.membershipArea.membershipHeader.profile }}
              </div>
              <div
                @click="$router.push('/membership-area/matches-preferences'),(hover = false)"
                :class="[
                  $route.path == '/membership-area/matches-preferences'
                    ? 'active'
                    : ''
                ]"
                class="dropDownItem"
              >
                <div
                  class="preferencesIcon icon"
                  @blur="
                    $t(
                      $i18n.locale
                    ).keys.membershipArea.membershipHeader.preferences =
                      $event.target.innerText
                  "
                ></div>
                {{ $t($i18n.locale).keys.membershipArea.membershipHeader.preferences }}
              </div>
              <div
                @click="$router.push('/membership-area/my-account'), (hover = false)"
                class="dropDownItem"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipHeader.myAccount =
                    $event.target.innerText
                "
              >
                <div class="accountIcon icon"></div>
                {{
                $t($i18n.locale).keys.membershipArea.membershipHeader
                .myAccount
                }}
              </div>

              <div
                @click="$router.push('/logout'), (hover = false)"
                class="dropDownItem"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipHeader.logout =
                    $event.target.innerText
                "
              >
                <div class="logoutIcon icon"></div>
                {{
                $t($i18n.locale).keys.membershipArea.membershipHeader.logout
                }}
              </div>
            </div>
            </transition>
          </div>
          <div class="navItem">
          <div class="navItemWrapper navigationButton" @click="showMobileMenu()">
            <span></span>
            <span></span>
            <span></span>
          </div>
          </div>
        </div>
      </div>
      <slide-up-down
        class="mobileHeaderWrapper"
        :active="isVisible"
        :duration="1000"
        v-if="isVisible && !premium"
      >
        <div class="topWrapper">
          <div class="logoWrapper">
            <router-link to="/membership-area/home">
              <div class="logoMobile">
                <img :src="$store.getters.mainLogos.mobile" />
              </div>
            </router-link>
          </div>
          <span class="mobileNavigationButton" @click="showMobileMenu()">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
        <div class="itemsWrapper">
          <router-link to="/membership-area/home" class="home navItem">
            <div class="navItem-icon" @click="showMobileMenu()">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'home-icon.svg')" class="homeMobileIcon icon" alt="Home icon" />
            </div>
            <p
              class="navItem-text"
              @click="showMobileMenu()"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.membershipArea.membershipHeader.home =
                  $event.target.innerText
              "
            >
              {{
              $t($i18n.locale).keys.membershipArea.membershipHeader.home
              }}
            </p>
          </router-link>
          <router-link to="/membership-area/messages" class="messages navItem">
            <div class="navItem-icon" @click="showMobileMenu()">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'envelope-fixed-white.svg')" class="messageMobileIcon icon" alt="Home icon" />
              <div
                  class="number"
                  v-if="this.$store.getters.chatNewMessages > 0 && this.$store.getters.chatNewMessages < 100"
                >{{ this.$store.getters.chatNewMessages }}</div>
                <div class="number bigNum" v-else-if="this.$store.getters.chatNewMessages >= 100">99+</div>
            </div>
            <p
              class="navItem-text"
              @click="showMobileMenu()"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.membershipArea.membershipHeader.messages =
                  $event.target.innerText
              "
            >
              {{
              $t($i18n.locale).keys.membershipArea.membershipHeader.messages
              }}
            </p>
          </router-link>
          <router-link to="/membership-area/matches" class="matches navItem">
            <div class="navItem-icon" @click="showMobileMenu()">
              <img
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'puzzle_white-icon.svg')"
                class="matchesMobileIcon icon"
                alt="Matches icon"
              />
              <div
                class="number"
                v-if="$store.getters.matchesQuantity > 0 && $store.getters.matchesQuantity < 100"
              >{{ $store.getters.matchesQuantity }}</div>
            <div
                class="number bigNum"
                v-else-if="$store.getters.matchesQuantity >= 100"
              >99+</div>
            </div>

            <p
              class="headerMatches navItem-text"
              @click="showMobileMenu()"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.membershipArea.membershipHeader.matches =
                  $event.target.innerText
              "
            >
              {{
              $t($i18n.locale).keys.membershipArea.membershipHeader.matches
              }}
            </p>
          </router-link>
          <router-link to="/membership-area/visitors" class="visitors navItem">
            <div class="navItem-icon" @click="showMobileMenu()">
              <img
                class="visitorsMobileIcon icon"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'eye-white-icon.svg')"
                alt="Visitors icon"
              />
              <div class="number" v-if="visitorsQuantity > 0 && visitorsQuantity < 100">{{ visitorsQuantity }}</div>
                <div class="number bigNum" v-else-if="visitorsQuantity >= 100">99+</div>
            </div>
            <p
              class="headerVisitors navItem-text"
              @click="showMobileMenu()"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.membershipArea.membershipHeader.visitors =
                  $event.target.innerText
              "
            >
              {{
              $t($i18n.locale).keys.membershipArea.membershipHeader.visitors
              }}
            </p>
          </router-link>
          <router-link to="/membership-area/meetings" class="meetings navItem" v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM') && !totalMeetings == 0">
            <div class="navItem-icon" @click="showMobileMenu()">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'heart-white-icon.svg')" class="icon" alt="Meetings icon" />
              <div class="number" v-if="totalMeetings > 0 && totalMeetings < 100">{{ totalMeetings }}</div>
              <div class="number bigNum" v-else-if="totalMeetings >= 100">99+</div>
            </div>
            <p
              class="headerHaveYouMet navItem-text"
              @click="showMobileMenu()"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t(
                  $i18n.locale
                ).keys.membershipArea.membershipHeader.haveYouMet =
                  $event.target.innerText
              "
            >
              {{
              $t($i18n.locale).keys.membershipArea.membershipHeader.haveYouMet
              }}
            </p>
          </router-link>
          <router-link to="/membership-area/user-profile/profile" class="profile navItem">
            <div class="navItem-icon" @click="showMobileMenu()">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'profile-icon.svg')" class="icon" alt="Personality icon" />
            </div>
            <p
              class="navItem-text"
              @click="showMobileMenu()"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.membershipArea.membershipHeader.profile =
                  $event.target.innerText
              "
            >
              {{
              $t($i18n.locale).keys.membershipArea.membershipHeader.profile
              }}
            </p>
          </router-link>
          <router-link to="/membership-area/matches-preferences" class="preferences navItem">
            <div class="navItem-icon" @click="showMobileMenu()">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'preferences-icon.svg')" class="icon" alt="Personality icon" />
            </div>
            <p
              class="navItem-text"
              @click="showMobileMenu()"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t(
                  $i18n.locale
                ).keys.membershipArea.membershipHeader.preferences =
                  $event.target.innerText
              "
            >
              {{
              $t($i18n.locale).keys.membershipArea.membershipHeader
              .preferences
              }}
            </p>
          </router-link>
          <router-link to="/membership-area/my-account" class="account navItem">
            <div class="navItem-icon" @click="showMobileMenu()">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'myaccount-icon.svg')" class="icon" alt="Personality icon" />
            </div>
            <p
            class="navItem-text"
              @click="showMobileMenu()"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t(
                  $i18n.locale
                ).keys.membershipArea.membershipHeader.myAccount =
                  $event.target.innerText
              "
            >
              {{
              $t($i18n.locale).keys.membershipArea.membershipHeader.myAccount
              }}
            </p>
          </router-link>
          <router-link to="/logout" class="logout navItem">
            <div class="navItem-icon">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'logout-icon.svg')" class="icon" alt="Personality icon" />
            </div>
            <p
              class="navItem-text"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.membershipArea.membershipHeader.logout =
                  $event.target.innerText
              "
            >{{ $t($i18n.locale).keys.membershipArea.membershipHeader.logout }}</p>
          </router-link>
          <Footer v-if="false"></Footer>
          <div class="memberPhotoWrapper">
            <div class="memberPhoto"></div>
          </div>
        </div>
      </slide-up-down>
    </header>
    <div class="successMessage" v-if="success">
      <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'Check-white.svg')" alt="Check icon" />
      <p
        v-langErr
        :contenteditable="editable"
        @blur="
          $t($i18n.locale).keys.membershipArea.membershipHeader.changeSavedSuccessfully =
            $event.target.innerText
        "
      >{{$t($i18n.locale).keys.membershipArea.membershipHeader.changeSavedSuccessfully}}</p>
    </div>
    <div v-if="!this.$store.getters.userRole.includes('ROLE_PREMIUM') && !premium" class="premiumButtonWrapper">
      <router-link to="/premium-plans/plans?direct=1" class="premiumButton">
        <span
          v-langErr
          :contenteditable="editable"
          @blur="
            $t($i18n.locale).keys.membershipArea.membershipHeader.become =
              $event.target.innerText
          "
        >
          {{
          $t($i18n.locale).keys.membershipArea.membershipHeader.become
          }}
        </span>
        <span
          class="headerPremiumMember"
          v-langErr
          :contenteditable="editable"
          @blur="
            $t(
              $i18n.locale
            ).keys.membershipArea.membershipHeader.premiumMember =
              $event.target.innerText
          "
        >
          {{
          $t($i18n.locale).keys.membershipArea.membershipHeader.premiumMember
          }}
        </span>
      </router-link>
    </div>
    <transition name="fade">
      <Alerts :type="alertType" :message="alertMessage" v-if="showAlerts" />
    </transition>

    <div id="tour" v-if="!premium">
      <b-modal id="tourModal" v-model="modalTourShow" hide-footer hide-header>
        <div class="exit-button" @click="modalTourShow = false">
          <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'Close-cross-White.svg')" alt="Exit icon" />
        </div>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe width="1420" height="1315" src="https://www.youtube.com/embed/dQw4w9WgXcQ"></iframe>
        </div>
      </b-modal>
    </div>
    <div id="coaching" v-if="!premium">
      <b-modal id="coachingModal" v-model="modalCoachingShow" hide-footer hide-header>
        <div class="exit-button" @click="modalCoachingShow = false">
          <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'Close-cross-Grey.svg')" alt="Exit icon" />
        </div>
        <div class="exit-button-mobile" @click="modalCoachingShow = false">
          <img :src="$store.getters.mainLogos.desktop" alt="Logo" />

          <span class="mobileNavigationButton" @click="showMobileMenu()">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
        <Coaching></Coaching>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Coaching from "@/components/membership-area/MembershipCoaching.vue";
import Alerts from "@/components/generic/Alerts.vue";
import Footer from "@/components/membership-area/MembershipFooter.vue";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "MembershipHeader",
  components: { Coaching, Footer, Alerts },
  data() {
    return {
      url: process.env.VUE_APP_URL,
      isVisible: false,
      hover: false,
      modalTourShow: false,
      modalCoachingShow: false,
      profilePic: null,
      profileGender: null,
      success: false,
      messagesIntervalHandle:null,
      messagesIntervalTime:30000,
      alertType: "",
      alertMessage: "",
      showAlerts: false,
      newMessagesCounter: 0,
      menuCountIntervalHandle:null,
      menuCountIntervalTime:30000,
    };
  },
  props: {
    premium: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters([
      "matchesQuantity",
      "totalMeetings",
      "visitorsQuantity",
      "editable",
      "getSuccessStatusMessage",
      "isMobile",
      "alerts",
      "user",
      "onlineUsers"

    ]),
    showOnlineUserMenu: function(){
      //if ( this.$store.getters.userRole.includes('ROLE_PREMIUM') ) return true;

      return false;
    }
  },
  watch: {
    getSuccessStatusMessage: function() {
      if (this.$store.getters.getSuccessStatusMessage == true) {
        this.success = this.$store.getters.getSuccessStatusMessage;
        setTimeout(() => {
          this.success = false;
          setTimeout(() => {
            this.$store.dispatch("showSuccessStatusMessage");
          }, 500);
        }, 4000);
      }
    },
    alerts: function(alert){
      console.log('ALERT!!!', alert);

      this.showAlerts = true;
      this.alertType = 'default';
      this.alertMessage = alert.alertMessage;
      setTimeout(() => {
        this.showAlerts = false;
        this.alertType = '';
        this.alertMessage = '';
      }, 3000);
    }
  },
  methods: {

    async getProfileImage() {
      return await axios
        .get(this.url + `/api/profile/my_picture`)
        .then(response => {
          return (
            (this.profilePic = response.data.data.public_path),
            (this.profileGender = response.data.data.gender)
          );
        });
    },
    showMobileMenu() {
      this.isVisible = !this.isVisible;
    },
    removeModal() {
      this.modalTourShow = false;
      this.modalCoachingShow = false;
    },
    addActive(event) {
      document.querySelectorAll(".dropDownItem").forEach(item => {
        item.classList.remove("active");
      });
      event.target.classList.add("active");
    }
  },
  mounted() {
    console.log('GLOBAL ISMOBILE *********************', this.$store.getters.isMobile);
    // this.$store.dispatch("showLoader");
    // this.$store.dispatch("setErrorVisibility", false);
    // this.$store.dispatch("hideContent");
    this.getProfileImage();
    console.log("header");

    if(this.showOnlineUserMenu){
      this.$store.dispatch("fetchOnlineUsers").then(res => {
        console.log('fetchOnlineUsers done', res)


      });
    }

    this.$store.dispatch("matchesCount").then(res => {
      this.$store.dispatch("addMatchCount");

      if (this.$store.getters.getMatchesActionsCount == 6) {
        //this.$store.dispatch("hideLoader");
      }
      if (res == "error") {
        if (this.$store.getters.getMatchesActionsCount == 6) {
          //this.$store.dispatch("setErrorVisibility", true);
        }
      } else {
        if (this.$store.getters.getMatchesActionsCount == 6) {
          this.$store.dispatch("showContent");
        }
      }
    });
    this.$store.dispatch("visitorsCount").then(res => {
      if (this.$store.getters.getMatchesActionsCount == 6) {
        //this.$store.dispatch("hideLoader");
      }
      if (res == "error") {
        if (this.$store.getters.getMatchesActionsCount == 6) {
          //this.$store.dispatch("setErrorVisibility", true);
        }
      } else {
        if (this.$store.getters.getMatchesActionsCount == 6) {
          this.$store.dispatch("showContent");
        }
      }
    });

    this.$store.dispatch('getNewMessagesNumber').then(res => {
      this.newMessagesCounter = parseInt(res);
    });

    //autoupdate for messages
    this.messagesIntervalHandle = setInterval(() => {
      this.$store.dispatch('getNewMessagesNumber').then(res => {
        console.log('getNewMessagesNumber', parseInt(res));
        if(res > this.newMessagesCounter){
          this.showAlerts = true;
          this.alertType = 'message';
          setTimeout(() => {
            this.showAlerts = false;
            this.alertType = '';
          }, 30000);
        }
        this.newMessagesCounter = parseInt(res);
      });
    }, this.messagesIntervalTime);

    //autoupdate match & visitor counts
    this.menuCountIntervalHandle = setInterval(() => {

      this.$store.dispatch("matchesCount");
      this.$store.dispatch("visitorsCount");

    }, this.menuCountIntervalTime);

    document.addEventListener('scroll',function(){
      //console.log('scroll', evt);
      if(window.scrollY == 0) {
        $('body').css('padding-top', 0);
        //$('#mainPanel').css('padding-bottom', 0);
        $('#stickySection').removeClass('sticky');
      }else{
        var bodyMargin = $('#stickySection').height();
        $('body').css('padding-top', bodyMargin + 'px');
        //$('#mainPanel').css('padding-bottom', bodyMargin + 'px');
        $('#stickySection').addClass('sticky');
      }
    });


  },
  destroyed() {
    clearInterval(this.messagesIntervalHandle);
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active{
  transition: all .5s ease;
}


.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
  max-height:0px;
}

.fade-enter-to, .fade-leave {
  max-height: 44px;
}
</style>
