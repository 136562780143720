<template>
  <div id="questionType10" class="questionComponent">
    
    <h1 class="questionText" v-if="variant != 2">
      {{ questionData.question.translations[selectedLanguage].question }}
    </h1>
                               <img  v-if="editable"
              :style="{ marginLeft: '20px' }"
              src="../../assets/images/Translate-Icon.svg"
              alt="Translation icon"
            />
    <p
      v-langErr :contenteditable="editable"
      @blur="
        $t($i18n.locale).keys.generic.skippableMessage = $event.target.innerText
      "
      class="skippableQuestionMessage"
      v-if="questionData.skippable == 1"
    >
      {{ $t($i18n.locale).keys.generic.skippableMessage }}
    </p>
    <!--
    <div class="sliderWrapper">
      <vue-slider
        id="heightFilter"
        style="width: 100%"
        v-model="height"
        :min="140"
        :max="200"
        :tooltip="'always'"
        :tooltip-placement="'top'"
        :enable-cross="false"
        :tooltip-formatter="formatter"
      ></vue-slider>
    </div>
    -->
    <div class="chatStyleAnswer">
      <div class="answerWrapper">
        <div class="sliderWrapper">
          <vue-slider
            id="rangeSlider"
            style="width: 90%"
            v-model="rangeValue"
            :min="minValue"
            :max="maxValue"
            :tooltip="'always'"
            :tooltip-placement="'bottom'"
            :tooltip-formatter="formatter"
            :enable-cross="false"
            :interval="interval"
          ></vue-slider>
        </div>
      </div>
    </div>
    
    <QuestionNavigation
      v-if="variant == 2"
      :progressData="{
        ...navData,
        currentQuestion: questionData.question.orderId,
      }"
      :nextDisabled="false"
      :prevDisabled="false"
      
      @sendAnswer="sendAnswer"
      @goBack="$emit('goBack')"

    />
    <button
      v-else
      v-langErr :contenteditable="editable"
      @click="
        sendAnswer();
        resendAnswer();
      "
      class="genericButton btnContinue"
    >
      {{ $t($i18n.locale).keys.generic.continueBtn }}
    </button>
    
    <p
      v-langErr :contenteditable="editable"
      @blur="
        $t($i18n.locale).keys.generic.answerLaterMessage =
          $event.target.innerText
      "
      v-if="questionData.question.skippable == 1"
      @click="
        emptyAnswers();
        sendAnswer();
        resendAnswer();
      "
      class="answerLaterMessage"
    >
      {{ $t($i18n.locale).keys.generic.answerLaterMessage }}
    </p>
  </div>
</template>

<script>
import QuestionNavigation from "@/components/personality/QuestionNavigation_variant2.vue";

export default {
  props: [
    "questionData",
    "variant",
    "navData"
  ],
  components: {
    QuestionNavigation
  },
  name: "QuestionType10",
  data() {
    return {
      //height: 170,
      formatter: "{value}",
      rangeMoved: false,
      lockSend: false,
      rangeValue: [40,100],
      minValue: 40,
      maxValue: 100,
      interval: 1,
    };
  },
  watch: {
    questionData: function(newVal, oldVal) {
      if (newVal.question.id > oldVal.question.id) {
        this.rangeValue = [this.minValue,this.maxValue];
      } else {
        if (this.questionData.answered) {
          this.rangeValue = [this.questionData.answered.answer[0], this.questionData.answered.answer[1]];
        }
      }
    }
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    },
    selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },
    metricUnits: function(){
      return (this.$store.getters.getCountry != 'uk');
    }
  },
  methods: {
    sendAnswer() {
      if(this.lockSend) return true;
      if (!this.questionData.answered) {
        this.lockSend = true;
        this.$emit("post-answer", this.rangeValue);
        setTimeout(this.lockSend = false,3000);
      }
      else if (this.questionData.answered) {
        this.$emit("put-answer", {
          answered: this.rangeValue,
          customerAnswerId: this.questionData.answered.id
        });
      }
    },
    resendAnswer() {
      if (this.questionData.answered) {
        this.$emit("put-answer", {
          answered: this.rangeValue,
          customerAnswerId: this.questionData.answered.id
        });
      }
    },
    emptyAnswers() {
      this.answersArray = [];
    },
    loadNextQuestion() {
      this.$emit("load-next-question");
    },
    loadAnswers() {
      if (this.questionData.answered) {
        this.rangeValue = [this.questionData.answered.answer[0], this.questionData.answered.answer[1]];
      }
    },


  },
  created() {
    if(this.questionData.question.id == 8){
      if(!this.metricUnits){
        //set values to foot
        this.interval = 0.01;
        this.minValue = 4.5;
        this.maxValue = 6.6;
        this.rangeValue = [4.5,6.6];
        this.formatter = v => {
          var measurement = v;
          var feet = parseInt(measurement);
          var fraction = measurement - feet;
          var inches = parseInt((12.0 * fraction));
          return feet + 'ft ' + inches + 'in';
        };
      }else{
        this.minValue = 140;
        this.maxValue = 200;
        this.rangeValue = [140,200];
        this.formatter = "{value}cm";
        this.interval = 1;
      }
    }else {
      if(host == "xn--liebeber60-eeb" || host == '60slove'){
        this.minValue = 50;
        this.maxValue = 100;
      }else if(host == "xn--liebeber40-eeb"){
        this.minValue = 30;
        this.maxValue = 100;
      }
    }
    this.loadAnswers();

  }
};
</script>

<style lang="scss" scoped></style>
