<template>
  <div id="questionType8" class="questionComponent">
    
    <h1 class="questionText" v-if="variant != 2">
      {{ questionData.question.translations[selectedLanguage].question }}
    </h1>
    <p
      v-langErr 
      class="skippableQuestionMessage"
      v-if="questionData.skippable == 1"
    >
      {{ $t($i18n.locale).keys.generic.skippableMessage }}
    </p>
    <div class="chatStyleAnswer">
      <div class="answerWrapper">
        <div class="sliderWrapper">
          <vue-slider
            id="heightFilter"
            style="width: 100%"
            v-model="height"
            :min="sliderProps.min"
            :max="sliderProps.max"
            :tooltip="'always'"
            :tooltip-placement="'top'"
            :enable-cross="false"
            :tooltip-formatter="sliderProps.formatter"
            :interval="sliderProps.interval"
          ></vue-slider>
        </div>
      </div>
     </div>
    
    <QuestionNavigation
      v-if="variant == 2"
      :progressData="{
        ...navData,
        currentQuestion: questionData.question.orderId,
      }"
      :nextDisabled="height == answerExist"
      :prevDisabled="false"
      
      @sendAnswer="sendAnswer"
      @goBack="$emit('goBack')"

    />

    <button
      v-else
      v-langErr 
      @click="
        sendAnswer();
        resendAnswer();
      "
      v-bind:class="{ 'btnContinue-disabled': height == answerExist() }"
      :disabled="height == answerExist"
      class="genericButton btnContinue"
    >
      {{ $t($i18n.locale).keys.generic.continueBtn }}
    </button>
                               <img  v-if="editable"
              :style="{ marginLeft: '20px' }"
              src="../../assets/images/Translate-Icon.svg"
              alt="Translation icon"
            />
    <input
      v-if="editable"
      v-model="$t($i18n.locale).keys.generic.continueBtn"
      type="text"
    />
                               <img  v-if="editable"
              :style="{ marginLeft: '20px' }"
              src="../../assets/images/Translate-Icon.svg"
              alt="Translation icon"
            />
    <p
      v-langErr :contenteditable="editable"
      @blur="
        $t($i18n.locale).keys.generic.answerLaterMessage =
          $event.target.innerText
      "
      v-if="questionData.question.skippable == 1"
      @click="
        emptyAnswers();
        sendAnswer();
        resendAnswer();
      "
      class="answerLaterMessage"
    >
      {{ $t($i18n.locale).keys.generic.answerLaterMessage }}
    </p>
  </div>
</template>

<script>
import QuestionNavigation from "@/components/personality/QuestionNavigation_variant2.vue";

export default {
  props: [
  "questionData",
    "variant",
    "navData"
  ],
  name: "QuestionType8",
  components: {
    QuestionNavigation
  },
  data() {
    return {
      height: 0,

      mywidth: 0,
      rangeMoved: false,
      lockSend: false,
      sliderProps: {
        interval: 1,
        min: 140,
        max: 200,
        formatter: "{value}cm",
      }
    };
  },
  watch: {
    questionData: function(newVal, oldVal) {
      if (newVal.question.id > oldVal.question.id) {
        this.height = "";
      } else {
        if (this.questionData.answered) {
          this.height = this.questionData.answered.answer[0];
        }
      }
    }
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    },
    selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },
    metricUnits: function(){
      return (this.$store.getters.getCountry != 'uk');
    }
  },
  methods: {
    sendAnswer() {
      console.log('send answer', this.lockSend);
      if(this.lockSend) return true;
      if (!this.questionData.answered) {
        this.lockSend = true;
        this.$emit("post-answer", [parseFloat(this.height)]);
        setTimeout(
          function(){
            this.lockSend = false;
          }.bind(this)
          ,3000
        );
      }
      else if (this.questionData.answered) {
        this.lockSend = true;
        this.$emit("put-answer", {
          answered: [parseFloat(this.height)],
          customerAnswerId: this.questionData.answered.id
        });
         setTimeout(
          function(){
            this.lockSend = false;
          }.bind(this)
          ,3000
        );
      }
    },
    resendAnswer() {
      console.log('resend answer', this.lockSend);
      if(this.lockSend) return true;
      if (this.questionData.answered) {
        this.lockSend = true;
        this.$emit("put-answer", {
          answered: [parseFloat(this.height)],
          customerAnswerId: this.questionData.answered.id
        });
         setTimeout(
          function(){
            this.lockSend = false;
          }.bind(this)
          ,3000
        );
      }
    },
    emptyAnswers() {
      this.answersArray = [];
    },
    loadNextQuestion() {
      this.$emit("load-next-question");
    },
    loadAnswers() {
      if (this.questionData.answered) {
        this.height = this.questionData.answered.answer[0];
      }
    },
    answerChanged(height) {
      if (this.questionData.answered) {
        if (height == this.questionData.answered.answer[0]) {
          true;
        } else {
          false;
        }
      }
    },
    answerExist() {
      if (this.questionData.answered) {
        return this.questionData.answered.answer[0];
      } else {
        return false;
      }
    },
    backgroundColor() {
      let backgroundGradient = !this.rangeMoved
        ? "linear-gradient(to right, #008860 0%, #008860 " +
          ((this.height - 140) * 100) / 60 +
          "%, #C2C7D2 30%, #C2C7D2 100%)"
        : "linear-gradient(to right, #008860 0%, #008860 " +
          ((this.height - 100) * 100) / 200 +
          "%, #C2C7D2 " +
          ((this.height - 100) * 100) / 200 +
          "%, #C2C7D2 100%)";
      backgroundGradient = String(backgroundGradient);
      return backgroundGradient;
    },
    setBackground() {
      this.rangeMoved = true;
    }
  },
  created(){
    if(!this.metricUnits){
        //set slider to foot
        this.sliderProps.interval = 0.01;
        this.sliderProps.min = 4.50;
        this.sliderProps.max = 6.60;
        this.sliderProps.formatter = v => {
          var measurement = v;
          var feet = parseInt(measurement);
          var fraction = measurement - feet;
          var inches = parseInt((12.0 * fraction));
          return feet + 'ft ' + inches + 'in';
        }
        if(this.height == 0) this.height = 5.60;

      }else {
        if(this.height == 0) this.height = 170;
      }
  },
  mounted() {
    this.loadAnswers();



  }
};
</script>

<style lang="scss" scoped></style>
