
//check for js logging
function getUrlParameter(url, parameter){
    parameter = parameter.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?|&]' + parameter.toLowerCase() + '=([^&#]*)');
    var results = regex.exec('?' + url.toLowerCase().split('?')[1]);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g,' '));
}

if( getUrlParameter(window.location.href, 'log') != '1' && process.env.NODE_ENV != 'development') {
  window.console.log = function (){return false;}
}

//Domain move for loveat50.com.au
    
if(window.location.hostname.includes('loveat50.com.au')){
  //Redirect URL
  const oldDomainURL = new window.URL(window.location.href); 
  var token = window.localStorage.getItem('token');
  var newDomainURL = new window.URL(`https://members-au.loveat50.com/magicLink/${token}`);
  
  //append overlay parameter
  newDomainURL.searchParams.append('com_au', 1)
  
  //append all current query parameters to new URL
  oldDomainURL.searchParams.forEach((val,key)=>{
    newDomainURL.searchParams.append(key,val)
  })

  console.log('newDomainURL', newDomainURL.href);

  window.location.href = newDomainURL.href;
  
}

//Load LiveAgent Integration
window.contactButton = null;
window.LA_event = null;
(function(d, src, c) { var t=d.scripts[d.scripts.length - 1],s=d.createElement('script');s.id='la_x2s6df8d';s.defer=true;s.src=src;s.onload=s.onreadystatechange=function(){var rs=this.readyState;if(rs&&(rs!='complete')&&(rs!='loaded')){return;}c(this);};t.parentElement.insertBefore(s,t.nextSibling);})(document, 'https://ais.ladesk.com/scripts/track.js',
  function(e){
    window.LA_event = e;
}); 

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue from "bootstrap-vue";
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import $axios from "./axios-instance";
import axios from "axios";
import VueGtm from 'vue-gtm';
import "./util/objectReplace.js";
// eslint-disable-next-line
import directives from "./directives"; //directive for missing keys fallback
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

const nodeEnv = process.env;

console.log('PROCESS', nodeEnv);

Vue.use(BootstrapVue);

import "@/assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css";

const themeAmourplusde40 = () => import("@/assets/sass/index_WLAmourplusde40.scss")
const themeAmourplusde50 = () => import("@/assets/sass/index_WLAmourplusde50.scss")
const themeAmourplusde60 = () => import("@/assets/sass/index_WLAmourplusde60.scss")
const theme60slove = () => import("@/assets/sass/index_WL60slove.scss")
const themeLiebeueber60 = () => import("@/assets/sass/index_WLliebeueber60.scss")
const themeLiebeueber50 = () => import("@/assets/sass/index_WLliebeueber50.scss")
const themeLiebeueber40 = () => import("@/assets/sass/index_WLliebeueber40.scss")
const themeLiebeab40 = () => import("@/assets/sass/index_WLLiebeab40.scss")
const themeLoveat40 = () => import("@/assets/sass/index_WLLoveat40.scss")
const themeLiebeue40 = () => import("@/assets/sass/index_WLliebeue40.scss")
const theme40slove = () => import("@/assets/sass/index_WL40slove.scss")
const theme40smeet = () => import("@/assets/sass/index_WL40smeet.scss")
const themeOur50s = () => import("@/assets/sass/index_WLOur50s.scss")
const themeLoveat50 = () => import("@/assets/sass/index_WLLoveat50.scss")
const themeLiebeue50 = () => import("@/assets/sass/index_WLLiebeue50.scss")
const themeDefault = () => import("@/assets/sass/index.scss")

/*
if(host == 'loveover50s' && false ){
  require("./assets/sass/index_WL.scss");
}else 
*/
if(host == '40smeet'){
  theme40smeet()
}else if(host == '40slove'){
  theme40slove()
}else if(host == 'our50s'){
  themeOur50s()
}else if(host == 'loveat50'){
  themeLoveat50()
}else if(host == 'xn--liebe50-r2a'){
  themeLiebeue50()
}else if(host == 'xn--liebe40-r2a'){
  themeLiebeue40()
}else if(host == 'liebeab40'){
  themeLiebeab40()
}else if(host == 'loveat40'){
  themeLoveat40()
}else if(host == 'xn--liebeber40-eeb'){
  themeLiebeueber40()
}else if(host == 'xn--liebeber50-eeb'){
  themeLiebeueber50()
}else if(host == 'xn--liebeber60-eeb'){
  themeLiebeueber60()
}else if(host == '60slove'){
  theme60slove()
}else if(host == 'amourplusde40'){
  themeAmourplusde40()
}else if(host == 'amourplusde50'){
  themeAmourplusde50()
}else if(host == 'amourplusde60'){
  themeAmourplusde60()
} else {
  themeDefault()
}

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import LoadScript from "vue-plugin-load-script";

import VueChatScroll from 'vue-chat-scroll';

import Vue2TouchEvents from 'vue2-touch-events';
Vue.use(Vue2TouchEvents);

Vue.prototype.$http = $axios;
Vue.prototype.$axios = axios;

Vue.use(VueChatScroll);

import * as VueGoogleMaps from "vue2-google-maps";

//import VueGeolocation from "vue-browser-geolocation";
//Vue.use(VueGeolocation);

import Geocoder from "@pderas/vue2-geocoder";
import i18n from "./i18n";

import Spinner from "@/components/generic/Spinner";
Vue.component("Spinner", Spinner);


import SlideUpDown from "vue-slide-up-down";
Vue.component("slide-up-down", SlideUpDown);

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

Vue.component('VueSlider', VueSlider);

//import VueRx from 'vue-rx';
//Vue.use(VueRx)

import VueAwesomeSwiper from 'vue-awesome-swiper';

// require styles
import 'swiper/css';

Vue.use(VueAwesomeSwiper, /* { default global options } */ )

Vue.use(Geocoder, {
  defaultCountryCode: null,
  defaultLanguage: null,
  defaultMode: "lat-lng",
  googleMapsApiKey: "AIzaSyDB7jmcrrmOm9yHaxNxqzR_C7NipFxA4h8"
});

Vue.use(LoadScript);

// Vue.loadScript(
//     "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=E3B87C16EBBCD73B799DBF2DCA7C79C3.uat01-vm-tx04");

//set language for google maps API
var localeToSet = "en";
if (domain == "de" || domain == "ch" || domain == "at") {
  localeToSet = "de";
}else if (domain == "cz") {
  localeToSet = "cz";
}else if (domain == "pl") {
  localeToSet = "pl";
}

let mapsUrl = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDB7jmcrrmOm9yHaxNxqzR_C7NipFxA4h8&libraries=places&callback=vueGoogleMapsInit&language="+localeToSet;

Vue.loadScript(
  mapsUrl
  )
  .then(() => {
    // console.log('kumpis fix')
    // Script is loaded, do something
  })
  .catch(() => {
    // console.log('not kumpis fix')
    // Failed to fetch script
  });

Vue.use(
  VueGoogleMaps,
  window.google
);

import VueSuggestion from 'vue-suggestion'


const eventHub = new Vue() // Single event hub

// Distribute to components using global mixin
Vue.mixin({
  data: function () {
    return {
      eventHub: eventHub
    }
  }
})

Vue.config.productionTip = false;

Vue.mixin({
  created: function () {
    var myOption = this.$options.myOption
    if (myOption) {
      // console.log(myOption)
    }
  }
});

//Set datalayer global
window.dataLayer = window.dataLayer || [];

Vue.use(VueGtm, {
  id: 'GTM-MVZJ9H8',
  queryParams: {  },
  enabled: false,
  debug: false,
  loadScript: true,
  vueRouter: router,
  ignoredViews: []
});

/*
window.$isMobile = true;
*/


window.isMobile = false;

document.addEventListener('resize',function(){
  if (window.innerWidth < 992) {
    window.isMobile = true;
  }else{
    window.isMobile = false;
  }
})


//Global App creation
  new Vue({
    VueSuggestion,
    router,
    store,
    i18n,
    data:{
      isMobile: window.isMobile
    },
    render: h => h(App)
  }).$mount("#app");


//check for DEV server
store.commit('set_isDEV', (process.env.NODE_ENV == 'development'));


// store.dispatch('setErrorVisibility', true);


//  instead of timeout handlerEnabled should be used
const isHandlerEnabled = (request) => {
  return request === false;
}
// (config={}) => {
//     return Object.prototype.hasOwnProperty.call(config, 'handlerEnabled') && !config.handlerEnabled ?
//       false : true
//   }

const requestHandler = (request) => {
  if (isHandlerEnabled(request.timeout)) {
    // console.dir(request)
    console.log(`Sending request to: ${request.url}`)
  }
  return request
}

const errorHandler = (error) => {

  console.log(`Request failed: ${error.config.url} with ${error}`);
  if (error.response) {
    //minor error
    console.log('ErrortHandler Request minor error', error.response);
  } else if (error.request) {
    //request failed or aborted
    console.log('ErrortHandler Request aborted or failed', error.request);
  } else {
    //Request failed completely
    console.log('ErrortHandler Request request failed completely', error);
    //store.dispatch('hideLoader');
    //store.dispatch('setErrorVisibility', true);
  }
  /*
   console.log('ErrortHandler', error.request);
  if (isHandlerEnabled(error.config.timeout)) {
    console.log("errorHandler", error);
    console.log(`Request failed: ${error.config.url} with ${error}`);
    store.dispatch('hideLoader');
    store.dispatch('setErrorVisibility', true);
  }
  */
  return Promise.reject({
    ...error
  });
}

const successHandler = (response) => {
  if (isHandlerEnabled(response.config.timeout)) {
    console.log(`Request done successfully: ${response.config.url}`)
    // store.dispatch('hideLoader');
  }
  return response;
}

// Add interceptors
axios.interceptors.request.use(
  request => requestHandler(request)
)

axios.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)

//network error ... why?
/*
axios.interceptors.response.use((response) => {
  if (response.data.error) {
    return Promise.reject(response);
  }
  return response;
}, (error) => (
  store.dispatch('setErrorVisibility', true),
  console.log(error) // result -> Error: Network Error(…)
));
*/




// Vue.directive('langErr', function (value) {
//   if(value.innerText.length == 0){
//     value.innerText = 'i18n key missing'
//   }});
//   // placeholder directive

// Vue.directive('phLangErr', function (value) {

//   if(value.placeholder.length == 0) {
//     value.placeholder = 'i18n key missing'
//   }
// });