<template>
  <div id="memberShipChatWindow"
  :class="{
    noMessages: (recipientInfo.id == -1 && !isLoading)
  }"
  >
    <Spinner v-if="isLoading" />
  <!--
    <div class="chatFreeAccountWrapper"  v-if="!this.$store.getters.userRole.includes('ROLE_PREMIUM')">
      <div class="contentWrapper">
        <img src="../../../assets/images/no-messages-icon.svg" alt="No meetings icon" />
        <p class="becomePremiumText">
          <span
            class="text1"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.membershipArea.membershipMessages.premiumOnly =
            $event.target.innerText"
          >{{$t($i18n.locale + '.keys.membershipArea.membershipMessages.premiumOnly')}}</span>

        </p>
        <div class="premiumButtonWrapper">
          <a href="/premium-plans" class="premiumButton">
            <span
              v-langErr
              :contenteditable="editable"
              @blur="
          $t($i18n.locale).keys.membershipArea.membershipHeader.become =
            $event.target.innerText
        "
            >
              {{
              $t($i18n.locale).keys.membershipArea.membershipHeader.become
              }}
            </span>
            <span
              class="headerPremiumMember"
              v-langErr
              :contenteditable="editable"
              @blur="
          $t(
            $i18n.locale
          ).keys.membershipArea.membershipHeader.premiumMember =
            $event.target.innerText
        "
            >
              {{
              $t($i18n.locale).keys.membershipArea.membershipHeader.premiumMember
              }}
            </span>
          </a>
        </div>
      </div>
    </div>
  -->

    <template>
      <div class="noMessageSelected" v-if="recipientInfo.id == -1 && !isLoading">
        <div class="contentWrapper">
          <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'no-messages-icon.svg')" alt="No meetings icon" />
          <p class="noMessagesText">
            <span
              class="text1"
              v-langErr
              :contenteditable="editable"
              @blur="
              $t($i18n.locale).keys.membershipArea.membershipMessages.noMessage1 =
                $event.target.innerText
            "
            >{{$t($i18n.locale).keys.membershipArea.membershipMessages.noMessage1}}</span>
            <br />
            <span
              class="text2"
              v-langErr
              :contenteditable="editable"
              @blur="
              $t($i18n.locale).keys.membershipArea.membershipMessages.noMessage2 =
                $event.target.innerText
            "
            >{{$t($i18n.locale).keys.membershipArea.membershipMessages.noMessage2}}</span>
          </p>
        </div>
        <!-- <img src="../../../assets/images/no-messages.svg" alt="" /> -->
      </div>
    </template>
    <template>
        <div
          v-if="recipientInfo.id !== -1"
          class="chatHeader chatHeaderMobile"
          :class="{'welcomeMsg': recipientInfo.id == 1}"
        >
          <img
            @click="returnChat()"
            class="chatBackBtn"
            src="../../../assets/images/Arrow-white-left.svg"
            alt
          />
          <div class="chatImage">
            <img v-if="recipientInfo.public_path" :src="recipientInfo.public_path" />
            <img v-else-if="parseInt(recipientInfo.gender) == 1" :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-man.svg')" />
            <img v-else-if="parseInt(recipientInfo.gender) == 2" :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-woman.svg')" />
          </div>

          <div class="userInfoWrapper">
            <div class="wrapper">
              <!--
              <img
                v-if="parseInt(recipientInfo.entertainment) == 1"
                src="../../../assets/images/heart-green.svg"
                alt="Heart icon"
              />
              -->
              <h1>
                <span class="username">{{ recipientInfo.firstName }}</span>
                <span class="age" v-if="recipientInfo.id != 1">, {{ getUserAge(recipientInfo.birthday) }}</span>
              </h1>
            </div>
            <div class="informationBottom" v-if="recipientInfo.id != 1">
              <div class="jobInfo">
                <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'Job_White-icon.svg')" alt="Job icon" />
                <p>{{ recipientInfo.profession }}</p>
              </div>
              <div class="locationInfo">
                <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'House_White-icon.svg')" alt="Location icon" />
                <p class="locationTag">{{ recipientInfo.location }}</p>
              </div>
            </div>
          </div>
          <div class="chatMenuWrapper">
            <img
              @click="dotsClicked = !dotsClicked"
              class="headerDots"
              :src="require('@/assets/images/' + $store.getters.assetsPath + 'Dots.png')"
              v-if="recipientInfo.id != 1"
            />

            <div class="chatMenuDropDown" v-if="dotsClicked">
              <img
                v-if="editable"
                :style="{ marginLeft: '20px' }"
                src="../../../assets/images/Translate-Icon.svg"
                alt="Translation icon"
              />
              <div
                @click="
                  $router.push(`/membership-area/member-profile/profile?id=${recipientInfo.matched_user_id}`), (dotsClicked = false);
                "
                class="dropDownItem"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipChat.buttons.goToProfile =
                    $event.target.innerText
                "
              >
                {{ $t($i18n.locale).keys.membershipChat.buttons.goToProfile }}
                <!-- Go to profile -->
              </div>
              <!-- Report messages
              <div
                @click="dotsClicked = false"
                class="dropDownItem"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipChat.buttons.reportMessages =
                    $event.target.innerText
                "
              >

                {{ $t($i18n.locale).keys.membershipChat.buttons.reportMessages }}
              </div>
              -->
              <div
                @click="$emit('delete-conversation', recipientInfo.matched_user_id);
                  (dotsClicked = false);
                "
                class="dropDownItem"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipChat.buttons.deleteConversation =
                    $event.target.innerText
                "
              >
                <!-- Delete conversation -->
                {{ $t($i18n.locale + '.keys.membershipChat.buttons.deleteConversation') }}
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="recipientInfo.id !== -1"
          id="chatContent"
          class="container"
          :class="{'welcomeMsg': recipientInfo.id == 1}"
        >
          <div class="row justify-content-center borderContainer">
            <div class="card card-default">
              <div class="card-body">
                <div class="conversationErrors" v-if="conversationErrors">
                  <ul>
                    <li v-for="(error, index) in conversationErrors" :key="index">
                      {{ error }}
                    </li>
                  </ul>
                </div>

                <div class="messagesLoading" v-if="messagesLoading"><i class="fa fa-spinner fa-spin"></i></div>
                <ul
                class="messagesListing"
                  @click="showEmojiPicker = false"
                  :class="{'noInput': recipientInfo.id == 1}"
                  ref="messagesListing"
                  :key = "pageNumber"
                  @scroll.passive="scrollHandler"
                >
                  <li
                    v-bind:class="{
                      messageReceived: message.sender !== 'me' && !message.type != 'date',
                      messageSent: message.sender == 'me' && message.type != 'date',
                      chatDate: message.type == 'date'
                    }"
                    v-for="(message, index) in currentChat"
                    :key="index"
                    :id="'msg_' + message.messageId"
                  >
                    <TypeMessage v-if="message.type == 1 || message.type == 6" v-bind:messageData="message"></TypeMessage>
                    <TypeMessageHTML v-if="message.type == -1" v-bind:messageData="message"></TypeMessageHTML>


                    <TypeComment v-if="message.type == 2" v-bind:messageData="message"></TypeComment>
                    <TypeLike v-if="message.type == 3" v-bind:messageData="message"></TypeLike>

                    <TypeSmile v-if="message.type == 4" v-bind:messageData="message"></TypeSmile>

                    <TypeInit v-if="message.type == 5 && currentChat.length == 1" v-bind:messageData="message"></TypeInit>
                    <TypePicRequest v-if="message.type == 7" v-bind:messageData="message" :hasProfilePic="hasProfilePic" :showPicRequest="showPicRequest" :recipientInfo="recipientInfo"></TypePicRequest>
                    <TypeIcebreaker v-if="message.type == 8" v-bind:messageData="message"></TypeIcebreaker>
                    <div v-if="message.type == 'date'">
                      {{message.message}}
                    </div>
                  </li>
                </ul>
                <div v-if="conversation[0] && conversation[0].expires  && !$store.getters.userRole.includes('ROLE_PREMIUM')">
                      <TypeExpire 
                        :expires="conversation[0].expires"
                        :match_id="conversation[0].matched_user_id"
                        @return-chat="returnChat" 
                      />
                </div>
                <!--
                <div v-if="!hasProfilePic && showPicRequest" id="pictureRequestBlock">
                  <p>
                  {{ this.recipientInfo.firstName }}
                  <span v-if="recipientInfo.gender == 1" v-html="$t($i18n.locale + '.keys.membershipChat.pictureRequestBlock.text_m')"></span>
                  <span v-if="recipientInfo.gender == 2" v-html="$t($i18n.locale + '.keys.membershipChat.pictureRequestBlock.text_f')"></span>
                  </p>
                  <router-link to="/membership-area/user-profile/gallery">
                    <button>
                      {{ $t($i18n.locale + '.keys.membershipChat.pictureRequestBlock.button') }}
                    </button>
                  </router-link>
                </div>
                -->
                <!-- works -->
                <form class="messageBox-form" @submit.prevent="send" v-if="recipientInfo.id != 1 && !conversationClosed">


                  <div class="grow-wrap">
                    <textarea name="text" id="text" rows="1" onInput="this.parentNode.dataset.replicatedValue = this.value"
                    ref="textarea"
                    v-model="buffer"
                    @focus="chatInputFocus($event)"
                    @blur="chatInputBlur($event)"
                     :placeholder="$t($i18n.locale).keys.membershipChat.typeHere"
                     @keydown="chatInputKey($event)"
                    ></textarea>
                  </div>

                  <!--
                  <textarea
                    class="messageBox"
                    contenteditable="true"
                    ref="textarea"
                    v-model="buffer"
                    @focus="chatInputFocus($event)"
                    @blur="chatInputBlur($event)"
                  ></textarea>

                  <textarea
                    v-on:keyup.enter="send"
                    ref="textarea"
                    type="text"
                    :placeholder="$t($i18n.locale).keys.membershipChat.typeHere"
                    class="messageBox"
                    v-model="buffer"
                    @focus="chatInputFocus($event)"
                    @blur="chatInputBlur($event)"
                  ></textarea>
                  -->
                  <button
                    class="emoji-trigger showEmoji"
                    :class="{ triggered: showEmojiPicker }"
                    @click.stop.prevent="toggleEmojiPicker"
                    id="emoji-button"
                  ></button>
                  <div @click.stop.prevent="send" class="sendMessage"></div>
                </form>
              </div>
            </div>
          </div>
          <!--
          <picker
            :style="{ position: 'absolute', bottom: '72px', left: '6px', zIndex:'99' }"
            v-show="showEmojiPicker"
            title="Pick your emoji..."
            emoji="point_up"
            @select="addEmoji"
            v-if="loadPicker"
          />
          -->
          <picker
            v-show="showEmojiPicker"
           :data="emojiIndex"
           @select="addEmoji"
           :style="{ position: 'absolute', bottom: '72px', left: '6px', zIndex:'99', maxWidth:'95%' }"
           title="Pick your emoji..."
            emoji="point_up"
          />
        </div>
    </template>
  </div>
</template>

<script>
/**
 * The types of the messages
 * TYPE_MESSAGE = 1 // done
 * TYPE_COMMENT = 2 // no designs
 * TYPE_LIKE = 3 //in progress
 * TYPE_SMILE = 4 //done
 * TYPE_INIT = 5 // no desgins
 * TYPE_ICEBREAKER = 6 // no desgins
 * TYPE_PICREQUEST = 7 // no desgins
 * TYPE_MESSAGEHTML = 8 // no desgins
 */
//import { Picker } from "emoji-mart-vue";
import data from 'emoji-mart-vue-fast/data/all.json'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
let emojiIndex = new EmojiIndex(data)
import 'emoji-mart-vue-fast/css/emoji-mart.css'

import getUserAge from "../../../helpers/userAge";
import formatDate from "../../../helpers/dateFormatter";
import TypeLike from "./messageTypes/TypeLike";
import TypeComment from "./messageTypes/TypeComment";
import TypeMessage from "./messageTypes/TypeMessage";
import TypeMessageHTML from "./messageTypes/TypeMessageHTML";
import TypeSmile from "./messageTypes/TypeSmile";
import TypeInit from "./messageTypes/TypeInit";
import TypeIcebreaker from "./messageTypes/TypeIcebreaker";
import TypePicRequest from "./messageTypes/TypePicRequest";
import TypeExpire from "./messageTypes/TypeExpire";
import Spinner from "@/components/generic/Spinner";

import axios from "axios";
import { mapActions } from "vuex";
// const BASE_URL = process.env.VUE_APP_URL;
export default {
  components: {
    Picker,
    TypeLike,
    TypeComment,
    TypeMessage,
    TypeMessageHTML,
    TypeSmile,
    TypeInit,
    TypeIcebreaker,
    TypePicRequest,
    TypeExpire,
    Spinner
  },
  props: ["chatData", "conversation", "isLoading"],
  mounted() {
    //window.scrollTo(0,0);
    var setupInterval = setInterval(() => {
      if(document.querySelector('.messagesListing')){
        this.scrollToBottom();
        clearInterval(setupInterval);
      }
    }, 100);
    this.getProfileImage();
    this.setAutoupdaterInterval();
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    },
    currentChat: function() {
      var messages = [...this.$store.getters.messages].reverse();
      var latestDate = new Date().toLocaleDateString();
      var newMessages = [];

      messages.forEach((el, index) => {
        //eslint-disable-next-line
        if(el.type == 7 && el.sender != 'me') this.showPicRequest = el;
        
        //Check if conversation has been closed by other user
        //eslint-disable-next-line
        if(el.type == 6) this.conversationClosed = true;
        //eslint-disable-next-line
        else this.conversationClosed = false;

        let safariDate = el.created_at.replace(/ /g, "T");
        let messageDate = new Date(safariDate); //iOS Safari needs this format
        //console.log(`currentChat el ${index}`, messageDate);
        let dateMessage = {
          message: messageDate.toLocaleDateString('default', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
          type: 'date'
        }
        if(latestDate != messageDate.toLocaleDateString() && this.currentConversationId != 1){
          
          if(index != 0) newMessages.push(dateMessage);
          //console.log ('add DATE', messages)
          latestDate = messageDate.toLocaleDateString();
        }

        newMessages.push(el);
      });
      return newMessages;
    },
    recipientInfo: function() {
      return this.$store.getters.info;
    },
    currentConversationId: function(){
      return this.$store.getters.info.id;
    },

  },
  data() {
    return {
      scrollOptions: { enabled: false, always: false, smooth: false, notSmoothOnInit: true, scrollonremoved:false  },
      url: process.env.VUE_APP_URL,
      dotsClicked: false,
      currentTime: new Date(),
      showEmojiPicker: false,
      emojiIndex: emojiIndex,
      loadPicker: false,
      buffer: "",
      text: "",
      messages: [],
      hasProfilePic: true,
      showPicRequest: false,
      currentMessageCount: 0,
      messagesLoading: false,
      currentConversationIntervalHandle: null,
      currentConversationIntervalTime: 15000,
      chatScrolled: false,
      pageNumber: 0,
      conversationClosed: false,
      conversationErrors: [],
      chatSendLocked: false,
    };
  },
  updated() {
    //Check if new messages came in
    if(this.currentMessageCount != this.currentChat.length && $('.messagesListing').is(':visible')){
      //Set new message count
      this.currentMessageCount = this.currentChat.length;
      //Scroll to bottom
      // var messagesList = $('.messagesListing')[0];
      // if(messagesList.scrollTop < (messagesList.scrollHeight - messagesList.offsetHeight)){
      //   $('.messagesListing').animate({ scrollTop: $('.messagesListing li:last-child')[0].offsetTop }, 500);
      // }
    }
  },
  watch: {
    currentChat: function(newVal, oldVal){

      //console.log('currentChat watch messages', newVal[0]);

      if(typeof newVal[0] == 'undefined') return true;

      // Add date for the last message
      if( newVal.length - oldVal.length < 10 && this.currentConversationId != 1) {
        let safariDate = newVal[0].created_at.replace(/ /g, "T");
        let messageDate = new Date(safariDate); //iOS Safari needs this format
        //console.log(`currentChat watch el`, messageDate);
        let dateMessage = {
          message: messageDate.toLocaleDateString('default', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
          type: 'date'
        }

        newVal.unshift(dateMessage)
      }

    },
    currentConversationId: function() {
      console.log('currentConversationId watch', this.currentConversationId);
      this.setAutoupdaterInterval();      

    },
  },
  destroyed() {
    clearInterval(this.currentConversationIntervalHandle);
  },
  methods: {
    ...mapActions(["matchesDeleteConversation"]),

    setAutoupdaterInterval(){
      clearInterval(this.currentConversationIntervalHandle);
      if(this.currentConversationId != 1 && this.currentConversationId != -1){
        console.log('currentConversationId autoupdate');
        //autoupdate current conversation
        this.currentConversationIntervalHandle = setInterval(() => {
          console.log('currentConversationIntervalHandle');
          //this.$store.dispatch('getMessages', this.currentConversationId)
          let data = {
            conversationID: parseInt(this.currentConversationId),
            afterId: (this.currentChat.length) ? parseInt(this.currentChat[this.currentChat.length-1].messageId) : 0
          }
            this.$store.dispatch('updateChatMessages', data).then(res => {
            console.log('currentConversationIntervalHandle updateChatMessages', res);
            if(res.data.data.messages.length) this.scrollToBottom();
          });

        }, this.currentConversationIntervalTime);

        setTimeout(() => {
          this.scrollToBottom();
        }, 500);
      }
    },
    chatInputKey($event){
      if($event.keyCode == 13 && $event.ctrlKey == true){
        $event.preventDefault();        
        this.send();
      }
    },
    scrollToBottom(){
      document.querySelector('.messagesListing').scrollTo(0,document.querySelector('.messagesListing').scrollHeight)
    },

    scrollHandler(evt){

      console.log('scrollHandler', evt, this.messagesLoading, this.$refs.messagesListing.scrollTop)

      var el = evt.target;

      if(el.scrollTop < 0 ) el.scrollTop = 0;
      this.chatScrolled = el.scrollTop + el.clientHeight + 1 < el.scrollHeight;

      console.log('messagesListing scrollTop', el.scrollTop);


      if(this.messagesLoading || this.currentConversationId == 1 || el.scrollTop > 250) return false;

      this.messagesLoading = true;
      this.scrollOptions.enabled = false;

      var beforeMsg = 0;
      if(this.currentChat[0].type != 'date'){
        beforeMsg = this.currentChat[0].messageId
      }else{
        beforeMsg = this.currentChat[1].messageId
      }

      let data = {
        conversationID: parseInt(this.conversation[0].id),
        beforeId: parseInt(beforeMsg)
      }


      console.log('updateChatMessages before', this.$refs.messagesListing.scrollTop, this.$refs.messagesListing.clientHeight, this.$refs.messagesListing.scrollHeight);
      var beforeScrollHeight = this.$refs.messagesListing.scrollHeight;

      this.$store.dispatch('updateChatMessages', data).then(res => {
        console.log('scrollHandler updateChatMessages', res.data.data.messages.length);
        if(res.data.data.messages.length == 0){
          this.messagesLoading = false;
          return false;
        }
        console.log('updateChatMessages after', this.$refs.messagesListing.scrollTop, this.$refs.messagesListing.clientHeight, this.$refs.messagesListing.scrollHeight);
        var afterScrollHeight = this.$refs.messagesListing.scrollHeight;
        var scrollDiff = afterScrollHeight - beforeScrollHeight;

        console.log('updateChatMessages scrollDiff', scrollDiff);
         this.pageNumber++;

        this.$nextTick(() => {

          this.messagesLoading = false;
          if(this.$refs.messagesListing.scrollTop <= 250 && scrollDiff > 250) this.$refs.messagesListing.scrollTop = scrollDiff;
          else this.$refs.messagesListing.scrollTop = 255;
          //console.log('beforeMsg', document.querySelector('#msg_' + beforeMsg));
          //if(res.data.data.messages.length) document.querySelector('#msg_' + beforeMsg).scrollIntoView();


          setTimeout(() => {

          }, 500);


          //if(this.$refs.messagesListing.scrollTop < 200) this.scrollHandler();
        });


      });

    },

    async getProfileImage() {
      await axios
        .get(this.url + `/api/profile/my_picture`)
        .then(response => {
          return this.hasProfilePic = (response.data.data.public_path) ? true: false;
        });
    },

    returnChat() {
      this.$emit("return-chat", false);
    },
    send() {
      if(this.chatSendLocked) return false;

      let afterId = 0;
      if(this.currentChat.length > 0) afterId = parseInt(this.currentChat[this.currentChat.length-1].messageId)

      let data = {
        id: parseInt(this.recipientInfo.id),
        message: this.buffer,
        afterId: afterId
      };
      if (this.buffer == "") return;
      console.log("sendChat", this.conversation, this.$store.getters.user, this.conversation.length, this.conversation[0])
      if (
        this.$store.getters.user.isPremium || //Skip other checks if user is premium
        this.conversation.length < this.$store.getters.user.settings.writeMessages || // Count allowed messages 
        (this.conversation[0] && !this.conversation[0].blockSend) || //sending not blocked
        this.$store.getters.user.settings.paywallOnSend == 1 //Show Paywall On Send
        ) {
          this.chatSendLocked = true;
          this.$store.dispatch('sendChatMessage', data)
            .then(resp => {

              //Unlock sending function 
              this.chatSendLocked = false;

              console.log('sendChatMessage COMP', resp)
              if(resp.data.errors){
                this.conversationErrors.push(resp.data.errors); 
              }

              //Send to paywall
              if(resp.data.data.paywall) this.$router.push(`/premium-plans`);
              //this.$store.dispatch("setMessages", resp.data.data.messages);
              //update conversation status
              this.$store.dispatch('getConversations', 'autoupdate');
              this.buffer = "";
              this.scrollToBottom();
            })

          this.buffer = "";
          this.showEmojiPicker = false;
          this.$nextTick(() => {
            $(this.$refs.textarea).trigger('input');
          });

      } else {
        console.log("sendChat", "go to Premium")
        this.$router.push(`/premium-plans`);
      }
    },
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
      this.loadPicker = true;
    },
    addEmoji(emoji) {
      // this.buffer += emoji.native;
      const textarea = this.$refs.textarea;
      const cursorPosition = textarea.selectionEnd;
      const start = this.buffer.substring(0, textarea.selectionStart);
      const end = this.buffer.substring(textarea.selectionStart);
      this.buffer = start + emoji.native + end;
      this.$nextTick(() => {
        textarea.selectionEnd = cursorPosition + emoji.native.length;
      });
    },
    getUserAge(birthday) {
      return getUserAge(birthday);
    },
    formatDate(date) {
      return formatDate(date);
    },
    chatInputFocus(evt){
      console.log('chatInputFocus', evt);
      if(this.$store.getters.isMobile){
        window.scrollTo(0,document.body.scrollHeight);
      }
    },
    chatInputBlur(evt){

        console.log('chatInputBlur', evt);
        setTimeout(() => {
          if(this.$store.getters.isMobile) window.scrollTo(0,0);
          if($('.messagesListing li').length > 0) $('.messagesListing').animate({ scrollTop: $('.messagesListing li:last-child')[0].offsetTop }, 500);
        }, 200);
    }
  }
};
</script>

<style lang="scss" scoped>
#chatInput {
  width: 100%;
    min-height: 21px;
    margin: 0;
    padding: 0;
    color: #111111;
    font-family: sans-serif;
    background: none repeat scroll 0 0 transparent;
    border: 1px solid;
    border-radius: 0;
    box-shadow: none;
    resize: none;
    line-height: 20px;
    max-height: 80px;
    overflow-x: hidden;
    overflow-y: auto;
    white-space: pre-wrap;
    text-align: left;

}






</style>
