<template>
  <div id="membershipChat">
    <template>
      <div
        class="container-lg flexContainer"
        v-bind:class="{ showMobileChat: chatMobileClicked }"
      >
      <div id="conversationWrapper" class="tabs-wrapper">
        <div id="conversationFilter" class="searchTab-2">
          <div class="searchBarWrapper">
            <input
              v-model="searchName"
              class="searchBar"
              type="text"
              v-phLangErr
              :placeholder="$t($i18n.locale).keys.generic.search"
            />

            <div style="position: relative;" class="notifications-wraper">
              <div class="number">
                {{ conversations.length }}
              </div>
              <div class="messagesIcon">
                <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'envelope.svg')" />
              </div>
            </div>
          </div>
        </div>

        <div
          id="conversationList"
          class="searchTab"
          @scroll="conversationsScrollHandler"
          :key="conversationsListKey"
          ref="conversationListing"
        >
          <div
            @click="chatMobileClicked = true"
            style
            class="msgHistoryWrapper"
          >
            <div
              @click="getMessages(conversation.id)"
              v-for="conversation in filterConversations"
              :key="conversation.id"
              class="messageWrapper"
              v-bind:class="{
                messageSelected: conversation.id == currentConversationId,
                welcomeMsg: conversation.id == 1,
                onlineUser: conversation.online
              }"
            >

              <div class="message">
                <div
                  class="userPhoto"
                  :class="{
                    onlineUser: conversation.online
                  }"
                >
                  <img
                    v-if="conversation.public_path"
                    :src="conversation.public_path"
                    alt
                  />

                  <img
                    v-if="
                      !conversation.public_path &&
                        parseInt(conversation.gender) == 1
                    "
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-man.svg')"
                    alt
                  />
                  <img
                    v-if="
                      !conversation.public_path &&
                        parseInt(conversation.gender) == 2
                    "
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-woman.svg')"
                    alt
                  />
                </div>
                <img
                  @click.stop.prevent="
                    deleteConfirm(conversation.matched_user_id)
                  "
                  class="trashIcon"
                  :src="require('@/assets/images/' + $store.getters.assetsPath + 'Trash-button.svg')"
                  alt="Remove"
                  v-if="conversation.id != 1"
                />
                <div class="messageContent">
                  <div class="wrapper">
                    <!--
                    <img
                      v-if="parseInt(conversation.entertainment) == 1"
                      src="../../../assets/images/heart-green.svg"
                      alt="Heart icon"
                    />
                    -->
                    <p class="name">
                    <span class="username">{{ conversation.firstName }}</span>
                      <!-- {{conversation.firstName.length > 9 ? conversation.firstName.substring(0, 8).concat('...') : conversation.firstName }} -->
                      <span class="age" v-if="conversation.id != 1">,{{ getUserAge(conversation.birthday) }}</span>
                    </p>
                  </div>
                    <p class="date" v-if="conversation.id != 1">{{ formatDate(conversation.created_at) }}</p>
                  <!--
                  <p class="date">
                  {{ $d(new Date(conversation.created_at),'long', locale) }}<br>
                  </p>
                  -->
                  <p
                    v-bind:class="{
                      newMessage: conversation.new == 1,
                      blurred: conversation.blurred
                    }"
                    class="userMessage"
                    v-if="$store.getters.userRole.includes('ROLE_PREMIUM') || true"
                  >
                    {{ conversation.message }}
                  </p>
                  <router-link to="/premium-plans" v-else class="premiumLink">
                    {{$t($i18n.locale + '.keys.membershipArea.membershipMessages.premiumOnlyMsg')}}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


        <div
          id="chatWindow"
          :class="{
            mobileChat:isMobile
          }"
        >
          <MemberShipChatWindow
            @return-chat="returnChat"
            :conversation="currentConversation"
            @delete-conversation="deleteConfirm"
            :isLoading="chatIsLoading"
            v-if="chatMobileClicked"
          ></MemberShipChatWindow>
        </div>
      </div>
    </template>
    <b-modal id="welcome-modal" hide-footer hide-header @hidden="hideWelcomeModal">
    <div class="header">
    <img class="headerImage" src="../../../assets/images/welcome_header.jpg" />
    </div>
    <div class="content">
      <h1>{{ $t($i18n.locale + '.keys.welcomeModal.mainHeadline',{productName: $store.getters.productName,}) }}</h1>
      <h3>{{ $t($i18n.locale + '.keys.welcomeModal.subHeadline') }}</h3>
      <b-button block variant="primary" class="my-3" @click="hideWelcomeModal">
        {{ $t($i18n.locale + '.keys.welcomeModal.button') }}
      </b-button>
      <div class="checklist">
        <div class="checklist-item">
          <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-green.svg')" />
          <span>
          {{ $t($i18n.locale + '.keys.welcomeModal.checklist_1') }}
          </span>
        </div>
        <div class="checklist-item">
          <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-green.svg')" />
          <span>
          {{ $t($i18n.locale + '.keys.welcomeModal.checklist_2') }}
          </span>
        </div>
        <div class="checklist-item">
          <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-green.svg')" />
          <span>
          {{ $t($i18n.locale + '.keys.welcomeModal.checklist_3') }}
          </span>
        </div>
      </div>
    </div>
    <div class="bottom">
    {{ $t($i18n.locale + '.keys.welcomeModal.bottomInfo_1') }}
    <br><br>
    {{ $t($i18n.locale + '.keys.welcomeModal.bottomInfo_2') }}
    </div>

    </b-modal>
    <!-- Tracking IFRAME -->
    <iframe :src="trackingURL" v-if="trackingURL" style="display:none;"></iframe>
  </div>
</template>

<script>
//import MembershipMisc from "@/components/membership-area/MembershipMisc.vue";
import MemberShipChatWindow from "./MemberShipChatWindow";
import getUserAge from "../../../helpers/userAge";
import formattedDate from "../../../helpers/dateFormatter";
import { mapActions } from "vuex";

export default {
  name: "MembershipChat",
  components: { 
    //MembershipMisc, 
    MemberShipChatWindow },
  data() {
    return {
      url: process.env.VUE_APP_URL,
      chatMobileClicked: false,
      searchName: "",
      trackingURL: "",
      conversationIntervalHandle: null,
      conversationIntervalTime: 15000,
      currentConversationIntervalHandle: null,
      currentConversationIntervalTime: 15000,
      chatIsLoading: true,
      //locale: this.$store.getters.selectedLanguage,
      conversationsData: {
        offset: 0,
        limit:0
      },
      conversationsLoading: false,
      conversationsListKey: 0,
    };
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    },
    conversations: function() {
      return this.$store.getters.conversations;
    },
    filterConversations: function() {
      if (!this.searchName) return this.$store.getters.conversations;

      return this.conversations.filter(
        (conversation) =>
          conversation.firstName
            .toLowerCase()
            .indexOf(this.searchName.toLowerCase()) > -1
      );
    },
    currentConversationId: function(){
      return this.$store.getters.info.id;
    },
    currentConversation: function(){
      return this.conversations.filter(conversation => conversation.id == this.currentConversationId);
    },
    domain: function(){
      return domain;
    },
    isMobile: function(){
      if(this.$store.getters.isMobile) return true;
      else{
        //eslint-disable-next-line
        this.chatMobileClicked = true;
        return false;
      }
    },
  },
  watch: {
    conversations: function(newVal, oldVal){
      console.log('conversations watched', oldVal, newVal);
      if(newVal) {
        this.conversationsData.offset = newVal.length
      }
    },
    currentConversationId: function() {
      /*
      console.log('currentConversationId', this.currentConversationId);
      clearInterval(this.currentConversationIntervalHandle);
      console.log('currentConversationId', this.currentConversationId);
      if(this.currentConversationId != 1){
        console.log('currentConversationId autoupdate');
        //autoupdate current conversation
        this.currentConversationIntervalHandle = setInterval(() => {
          console.log('currentConversationIntervalHandle');
          this.$store.dispatch('getMessages', this.currentConversationId)
        }, this.currentConversationIntervalTime);
      }
      */
      //Check if current conversation is reset (-1)
      if(this.currentConversationId <= 0 ){
        //Prevent NavigationDuplicate
        if(this.$route.path != '/membership-area/messages') this.$router.push(`/membership-area/messages`);
      } else {
        //Set correct url for current conversation
        if(this.$route.params.id != this.currentConversationId) {
          this.$router.push(`/membership-area/messages/${this.currentConversationId}`);
        }
      }
    },
    $route: function(to) {
      //Return to conversation overview if no conversation id is set
      if(!to.params.id && this.isMobile) this.returnChat();
    }


  },

  mounted() {

      /*
      if (this.$route.query.reg == 1) {
        //console.log('SHOW WELCOME MODAL');
        //this.$bvModal.show('welcome-modal');

        var gtagInt = setInterval(
          function(){
        console.log('try gtag event');
        if(typeof window.gtag == 'function'){
          console.log('send gtag event');
          clearInterval(gtagInt);
          //window.gtag('event', 'conversion', {'send_to': 'AW-481807027/ywbICMCSz-YBELOV3-UB'});
          //window.gtag('event', 'conversion', {'send_to': 'AW-1037950593/TJrQCK_l7vQBEIG99-4D'});
        }
          },
          200
        )

         //Trigger Finish Event
        var URL = process.env.VUE_APP_URL + '/api/personality/trigger/finish';
        axios.post(URL).then(() => {
          console.log('Sent Finish Event');
        });

        //Trigger Tracking Events
        if(this.domain == 'ch'){
          console.log('Tracking Event start')
          this.trackingURL = `https://ch.dating-advisor.org/track.php?brand=acom&type=reg&gender=XX&age=35`;
        }else if(this.domain == 'cz'){
          this.trackingURL = "https://cz.dating-advisor.net/track.php?brand=acom&type=reg&gender=XX&age=35";
        }else if(this.domain == 'pl'){
          this.trackingURL = "https://pl.dating-advisor.net/track.php?brand=acom&type=reg&gender=XX&age=35";
        }


        try {
          window.fbq('track', 'CompleteRegistration',{currency: "GBP", value: 1});
          window.EF.conversion({
            aid: 96,
          });

        } catch (error) {
          console.log('Conversion Error', error);
        }

      }
      */


    window.scrollTo(0,0);

    console.log('current conversation?? ', this.currentConversationId);

    this.$store.dispatch("getConversations", this.conversationsData).then((response) => {
      //this.conversationsData.offset
      this.$store.dispatch("addActionCount");
      this.$store.dispatch("hideLoader");
      if (this.$store.getters.getChatActionsCount == 2) {
        //this.$store.dispatch("hideLoader");
      }
      if (response == "error") {
        // show error
        if (this.$store.getters.getChatActionsCount == 2) {
          //this.$store.dispatch("setErrorVisibility", true);
        }
      } else {
        console.log("getMessages",response );

        if(this.currentConversationId <= 0 && response.length > 0){
          //if(window.innerWidth >= 768 && this.$store.getters.userRole.includes('ROLE_PREMIUM')) this.$store.dispatch("getMessages", response[0].id);
          //this.$store.dispatch("getMessages", response[0].id);
          if(this.$route.params.id){
            this.getMessages(this.$route.params.id);
            this.chatMobileClicked = true;
          }
          else {
            if(response[0].id == 1) {
              this.getMessages(response[0].id);
              this.chatMobileClicked = true;
            }else if(!this.isMobile){
              this.getMessages(response[0].id);
            }
          }
        }else{
          this.chatMobileClicked = true;
          this.chatIsLoading = false;
        }

        if (this.$store.getters.getChatActionsCount == 2) {
          this.$store.dispatch("showContent");
        }
      }
      // return response;
      //Check if conversation list is filled and load more if needed
      this.$nextTick(() => {
        var conversationBox = document.getElementById('conversationList')
        var conversationList = document.querySelector('.msgHistoryWrapper');
        console.log('Conversation BoxH vs. ListH', conversationBox.offsetHeight, conversationList.offsetHeight)
        if(conversationBox.offsetHeight > conversationList.offsetHeight ) {
          console.log('Conversation List', this.$refs.conversationListing);
          this.conversationsScrollHandler()
        } 
      })

    });
    // .then((res) => {
    //   if(res){
    //     this.show = true;
    //   } else if (res == 'error'){
    //     // show error
    //   }
    // });

    //autoupdate for conversation list
    this.conversationIntervalHandle = setInterval(() => {
      this.$store.dispatch('getConversations', 'autoupdate');
    }, this.conversationIntervalTime);

  },
  destroyed() {
    clearInterval(this.conversationIntervalHandle);
    clearInterval(this.currentConversationIntervalHandle);
    //this.$store.dispatch("getMessages", -1);
    this.getMessages(-1);
  },

  methods: {
    ...mapActions(["matchesDeleteConversation"]),
    
    scrollToBottom(){
      console.log('chatwindowlog', 'scrollToBottom')
      var element = document.querySelector('.messagesListing');
      if(element) element.scrollTo(0,element.scrollHeight)
    },

    conversationsScrollHandler(){

      //console.log('conversationsScrollHandler', evt);

      var list = this.$refs.conversationListing;

      var loadOffset = 100;
      let scrollPosition = list.scrollTop;
      let scrollDiff = (list.scrollHeight - list.clientHeight) - list.scrollTop;
      //var beforeScrollHeight = list.scrollHeight;

      //console.log('conversationsScrollHandler pos', scrollDiff);


      if(scrollDiff < loadOffset && !this.conversationsLoading){
        //console.log('conversationsScrollHandler scrollPosition before req', this.$refs.conversationListing.scrollTop)
        this.conversationsLoading = true;
        this.$store.dispatch('getConversations', this.conversationsData).then(res => {
          console.log('conversationsScrollHandler result', res);
          if(res.length == 0){
            this.conversationsLoading = false;
            return false;
          }

          //var afterScrollHeight = list.scrollHeight;
          //var positionDiff = afterScrollHeight - beforeScrollHeight;

          this.conversationsListKey++;
          //console.log('conversationsScrollHandler scrollPosition before tick', this.$refs.conversationListing.scrollTop, positionDiff)
          scrollPosition = this.$refs.conversationListing.scrollTop;
          this.$nextTick(() => {
            //console.log('conversationsScrollHandler scrollPosition tick', this.$refs.conversationListing.scrollTop)
            //list.scrollTop = scrollPosition;
            this.$refs.conversationListing.scrollTop = scrollPosition;
            this.conversationsLoading = false;
          });



        });
      }

    },
    getMessages(conversationID) {
      this.chatIsLoading = true;
      /*
      if(!this.$store.getters.userRole.includes('ROLE_PREMIUM')){

        this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Read Message Attempt',
          label: 'Chat'
        });

        localStorage.setItem("premium", 'false');
        this.$router.push('/premium-plans');
        return false;
      }
      */
      console.log("getMessages");
      if(this.isMobile) this.$store.dispatch("showLoader");

      this.$store.dispatch("getMessages", conversationID).then((res) => {
        if (res == "error") {
          //this.$store.dispatch('setErrorVisibility', true);
          this.$store.dispatch("hideContent");
        } else {
        //  this.$store.dispatch("showContent");
          if(this.isMobile) this.$store.dispatch("hideLoader");
          this.chatIsLoading = false;
          //this.$store.dispatch("getConversations");
          this.scrollToBottom();
        }
      });
    },
    returnChat() {
      //this.$store.dispatch("getMessages", -1);
      this.getMessages(-1);
      this.chatMobileClicked = false;
    },
    getUserAge(birthday) {
      return getUserAge(birthday);
    },
    formatDate(date) {
      return formattedDate(date, this.$store.getters.selectedLanguage);
    },
    deleteConfirm(matched_user_id) {

      this.$bvModal.msgBoxConfirm(this.$t(this.$i18n.locale + '.keys.membershipChat.confirm.deleteConversationInfo'), {
          title: this.$t(this.$i18n.locale).keys.membershipChat.buttons.deleteConversation,
          //buttonSize: 'sm',
          okVariant: 'danger',
          modalClass: 'error-dialog',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          okTitle: this.$t(this.$i18n.locale + '.keys.membershipChat.confirm.btnYes'),
          cancelTitle: this.$t(this.$i18n.locale + '.keys.membershipChat.confirm.btnNo'),
        })
          .then(value => {
            //console.log('DELETE', value);
            if(value){
              this.matchesDeleteConversation(matched_user_id);
            }

          })
          .catch(err => {
            // An error occurred
            console.log('DELETE err', err);
          })

    },
    hideWelcomeModal() {
      const params = new URLSearchParams(window.location.search);
      params.delete('reg');
      if(Array.from(params).length > 0) window.history.replaceState({}, "", decodeURIComponent(`${window.location.pathname}?${params}`));
      else window.history.replaceState({}, "", decodeURIComponent(`${window.location.pathname}`));
      this.$bvModal.hide('welcome-modal');
    },
  },
};
</script>

<style lang="scss" scoped></style>
