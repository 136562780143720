var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"questionComponent",attrs:{"id":"questionType10"}},[(_vm.variant != 2)?_c('h1',{staticClass:"questionText"},[_vm._v(" "+_vm._s(_vm.questionData.question.translations[_vm.selectedLanguage].question)+" ")]):_vm._e(),(_vm.editable)?_c('img',{style:({ marginLeft: '20px' }),attrs:{"src":require("../../assets/images/Translate-Icon.svg"),"alt":"Translation icon"}}):_vm._e(),(_vm.questionData.skippable == 1)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"skippableQuestionMessage",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.generic.skippableMessage = $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.skippableMessage)+" ")]):_vm._e(),_c('div',{staticClass:"chatStyleAnswer"},[_c('div',{staticClass:"answerWrapper"},[_c('div',{staticClass:"sliderWrapper"},[_c('vue-slider',{staticStyle:{"width":"90%"},attrs:{"id":"rangeSlider","min":_vm.minValue,"max":_vm.maxValue,"tooltip":'always',"tooltip-placement":'bottom',"tooltip-formatter":_vm.formatter,"enable-cross":false,"interval":_vm.interval},model:{value:(_vm.rangeValue),callback:function ($$v) {_vm.rangeValue=$$v},expression:"rangeValue"}})],1)])]),(_vm.variant == 2)?_c('QuestionNavigation',{attrs:{"progressData":{
      ..._vm.navData,
      currentQuestion: _vm.questionData.question.orderId,
    },"nextDisabled":false,"prevDisabled":false},on:{"sendAnswer":_vm.sendAnswer,"goBack":function($event){return _vm.$emit('goBack')}}}):_c('button',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"genericButton btnContinue",attrs:{"contenteditable":_vm.editable},on:{"click":function($event){_vm.sendAnswer();
      _vm.resendAnswer();}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.continueBtn)+" ")]),(_vm.questionData.question.skippable == 1)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"answerLaterMessage",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.generic.answerLaterMessage =
        $event.target.innerText},"click":function($event){_vm.emptyAnswers();
      _vm.sendAnswer();
      _vm.resendAnswer();}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.answerLaterMessage)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }